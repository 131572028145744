import {
  Alert,
  Box,
  ColorSwatch,
  Group,
  Progress,
  Stack,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import PixiIcon from '@pixi/elements/Icon';
import { format, formatDuration } from 'date-fns';
import PixiButton from '@pixi/elements/Button';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import { openFileSettingsPopup } from '@pixi/AppController';

export default function AssetEditPanelThumbnail() {
  const { file, disabledSections } = useAssetEditPanel();
  return (
    <>
      {!disabledSections?.includes('thumbnail') && (
        <SectionCollapse label="Thumbnail" id="thumbnail">
          <Box
            style={{ aspectRatio: '4/3', overflow: 'hidden' }}
            pos="relative"
          >
            {!!file?.file?.previews?.length && (
              <TransparentPattern color="gray.2" />
            )}
            <AssetThumbnail
              size="small"
              file={file}
              h="100%"
              w="100%"
              style={() => ({
                objectFit: 'contain',
                position: 'relative',
                zIndex: 2,
              })}
            />
          </Box>
          <PixiButton
            variant="light"
            size="xs"
            onClick={() => {
              openFileSettingsPopup(file, {
                defaultTab: 'thumbnail',
              });
            }}
          >
            Edit
          </PixiButton>
        </SectionCollapse>
      )}
    </>
  );
}
