import { useKeysSelector } from '@pixi/store';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import { truncate } from '@pixi/helpers/utils';
import SelectFolder from '@pixi/components/SelectFolder';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiConfirm from '@pixi/elements/Confirm';

export default function AssetEditPanelFolders() {
  const { disabledSections, file, files, context, isBrand } =
    useAssetEditPanel();
  const folderIds =
    files.length > 1
      ? files.reduce((commonFolderIds, file) => {
          // Compute the intersection of commonFolderIds and file.folders
          return commonFolderIds.filter((folderId) =>
            file.folders?.includes(folderId),
          );
        }, files[0].folders || [])
      : file.folders || [];

  const folders = useKeysSelector(
    'FOLDERS',
    folderIds.map((f) => f.toString()),
  );

  // In the future, we will check if library has Folder capabilities
  if (!isBrand) {
    return <></>;
  }
  return (
    <>
      {!disabledSections?.includes('folders') && (
        <SectionCollapse label="Folders" id="folders">
          {folders.map((folder: Pickit.FolderInterface) => (
            <Detail
              key={folder._id}
              left={truncate(folder.name, 25, '...')}
              leftTooltip={folder.name}
              right={
                <PixiConfirm
                  title="Remove from folder"
                  description={
                    files?.length
                      ? 'Selected files will be removed from the folder.'
                      : 'Selected file will be removed from folder'
                  }
                  confirmLabel="Remove"
                  confirmProps={{
                    color: 'red',
                  }}
                  onConfirm={async () => {
                    if (files?.length) {
                      await context.saveDocumentsWhere(
                        {
                          _id: { $in: files.map((file) => file._id) },
                        },
                        {
                          $pull: {
                            folders: folder._id,
                          },
                        },
                      );
                    } else {
                      await context.savePartial({
                        _id: file._id,
                        $pull: {
                          folders: folder._id,
                        },
                      });
                    }
                  }}
                >
                  <PixiButton
                    size="xs"
                    px="xs"
                    variant="light"
                    color="dark"
                    w="auto"
                  >
                    <PixiIcon name="xmark" />
                  </PixiButton>
                </PixiConfirm>
              }
            />
          ))}
          <SelectFolder
            context={context}
            customFilter={(folder) => !folderIds.includes(folder._id)}
            target={
              <PixiButton
                size="xs"
                variant="light"
                leftSection={<PixiIcon name="rectangle-history-circle-plus" />}
              >
                Add to folder
              </PixiButton>
            }
            onFolderClick={async (folder) => {
              if (files?.length) {
                await context.saveDocumentsWhere(
                  {
                    _id: { $in: files.map((file) => file._id) },
                  },
                  {
                    $addToSet: {
                      folders: folder._id,
                    },
                  },
                );
              } else {
                await context.savePartial({
                  _id: file._id,
                  $addToSet: {
                    folders: folder._id,
                  },
                });
              }
            }}
          />
        </SectionCollapse>
      )}
    </>
  );
}
