import { useAssetEditPanel } from '../../context';
import { Anchor, Box, Group, Stack, Textarea, TextInput } from '@mantine/core';
import { Detail, SectionCollapse } from '../../elements';
import PixiForm from '@pixi/elements/Form';
import { openFileBrowser } from '@pixi/AppController';
import TagsForm from '@pixi/components/AssetQuickForms/TagsForm';

export default function AssetEditPanelIndexingGeneral() {
  const { file, files, context } = useAssetEditPanel();

  const allTags = files.reduce((tags: string[], file) => {
    return [...tags, ...file.tags];
  }, []);
  return (
    <SectionCollapse label="General" id="general" defaultOpen>
      <Stack gap="md">
        {!files?.length && (
          <Detail
            left="Name"
            right={file.name}
            column
            noDivider
            actions={[
              {
                label: 'edit',
                icon: 'pen',
                wrapper: (target) => (
                  <PixiForm<{ name: string }>
                    type="dropdown"
                    position="bottom-end"
                    title="Change name"
                    zIndex={999}
                    onSubmit={async (data) => {
                      await context.savePartial({
                        _id: file._id,
                        name: data.name,
                      });
                    }}
                    form={{
                      name: {
                        key: 'name',
                        value: file.name || '',
                        render: ({ value, setValue }) => (
                          <TextInput
                            value={value}
                            onChange={(event) => {
                              setValue(event.currentTarget.value);
                            }}
                            autoFocus
                            onFocus={(event) => event.currentTarget?.select()}
                          />
                        ),
                      },
                    }}
                    target={target}
                  />
                ),
              },
            ]}
          />
        )}
        <Detail
          left="Description"
          right={file.description}
          noDivider
          column
          actions={[
            {
              label: 'edit',
              icon: 'pen',
              wrapper: (target) => (
                <PixiForm<{ description: string }>
                  type="dropdown"
                  position="bottom-end"
                  title="Change description"
                  zIndex={999}
                  onSubmit={async (data) => {
                    await context.savePartial({
                      _id: file._id,
                      description: data.description,
                    });
                  }}
                  form={{
                    description: {
                      key: 'description',
                      value: file.description || '',
                      render: ({ value, setValue }) => (
                        <Textarea
                          value={value}
                          onChange={(event) => {
                            setValue(event.currentTarget.value);
                          }}
                          rows={5}
                          autoFocus
                          onFocus={(event) => event.currentTarget?.select()}
                        />
                      ),
                    },
                  }}
                  target={target}
                />
              ),
            },
          ]}
        />
        <Detail
          left="Tags"
          right={
            <Group w="100%" gap="5">
              {(!files?.length
                ? file.tags
                : allTags
                    .filter((tag) => {
                      return files.every((file) => file.tags.includes(tag));
                    })
                    .filter((tag, index, self) => self.indexOf(tag) === index)
              ).map((t, i) => {
                return (
                  <Box key={t}>
                    <Anchor
                      c="dark"
                      onClick={() => {
                        openFileBrowser(context.type, {
                          assetGridProps: {
                            defaultFilters: {
                              tags: [
                                {
                                  value: t,
                                },
                              ],
                            },
                          },
                        });
                      }}
                    >
                      {t}
                    </Anchor>
                    {i === file.tags?.length - 1 ? '' : ','}
                  </Box>
                );
              })}
            </Group>
          }
          column
          noDivider
          actions={[
            {
              label: 'edit',
              icon: 'pen',
              wrapper: (target) => (
                <TagsForm
                  file={file}
                  files={files}
                  context={context}
                  target={target}
                />
              ),
            },
          ]}
        />
      </Stack>
    </SectionCollapse>
  );
}
