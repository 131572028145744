import {
  Anchor,
  Badge,
  Box,
  Checkbox,
  Chip,
  Divider,
  Group,
  Loader,
  Paper,
  Stack,
  Switch,
  TagsInput,
  Textarea,
  TextInput,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiForm from '@pixi/elements/Form';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { useAssetEditPanel } from '../context';
import { Detail, SectionCollapse } from '../elements';
import License from '@pixi/helpers/License.json';
import { format } from 'date-fns';
import { useUserContext } from 'hooks';
import { truncate } from '@pixi/helpers/utils';
import { openInNewTab } from 'utils';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { openFileBrowser, openFileSettingsPopup } from '@pixi/AppController';
import useUserStatus from 'hooks/useUserStatus';
import PixiDropdown from '@pixi/elements/Dropdown';
import PropertyValueForm from '@pixi/components/AssetWorkflows/Properties/PropertyValueForm';
import TagsForm from '@pixi/components/AssetQuickForms/TagsForm';
import AssetEditPanelProperties from './Sections/Properties';
import AssetEditPanelApprovals from './Sections/Approvals';
import { getStore } from '@pixi/store';
import AssetEditPanelIndexingGeneral from './Sections/IndexingGeneral';
import AssetEditPanelFolders from './Sections/Folders';

export default function AssetEditPanelIndexing() {
  const User = useUserContext();
  const { file, context, disabledSections } = useAssetEditPanel();
  const { generateUrl } = useStorageUrl();
  const userStatus = useUserStatus();

  const { searchable, previewable, labels } = User.data.selectedCommunity
    ?.settings?.attributes || {
    searchable: [],
    previewable: [],
    labels: [],
  };

  function renderAttribute(key: string, value: any, cleanData?: any) {
    let valueString = '';
    try {
      valueString =
        cleanData?.type === 'number'
          ? parseInt(value).toString()
          : cleanData?.type === 'date' || cleanData?.type === 'datetime'
            ? format(
                new Date(value),
                cleanData?.type === 'date' ? 'PP' : 'PPpp',
              )
            : value?.toString();
    } catch (e) {
      valueString = value;
    }

    let valueRender = (
      <>
        {cleanData?.type === 'date' ||
        cleanData?.type === 'datetime' ||
        cleanData?.type === 'text' ||
        cleanData?.type === 'number' ? (
          valueString
        ) : cleanData?.type === 'boolean' ? (
          <>
            <Switch checked={value === 'true'} color="dark" />
          </>
        ) : (
          value
        )}
      </>
    );
    if (cleanData?.type === 'storageFiles') {
      valueRender = value?.map((file: Pickit.InterfaceStorageFile) => (
        <Anchor
          key={file.id}
          onClick={() => openInNewTab(generateUrl(file.url))}
          c="dark"
        >
          {truncate(decodeURIComponent(file.name), 50, '...')}
        </Anchor>
      ));
    }

    return (
      <Detail
        // icon={cleanData?.icon as PixiIconName}
        left={cleanData?.label || key}
        right={
          cleanData?.type === 'tags' ? (
            <Group gap="5">
              {valueString.split(',').map((keyword) => (
                <Badge key={keyword} color="dark" variant="light">
                  {keyword}
                </Badge>
              ))}
            </Group>
          ) : (
            valueRender
          )
        }
        rightProps={{
          ff: cleanData?.type === 'number' ? 'mono' : undefined,
        }}
        column={
          cleanData?.type === 'storageFiles' ? true : valueString?.length > 25
        }
      />
    );
  }

  return (
    <>
      <AssetEditPanelIndexingGeneral />
      <AssetEditPanelFolders />
      <AssetEditPanelProperties />
      {!disabledSections?.includes('approvals') && <AssetEditPanelApprovals />}
      {userStatus.activeFeatures?.ocr_and_fulltext_search &&
      file?.name &&
      (['jpg', 'jpeg', 'bmp', 'pdf', 'tiff', 'tif', 'png', 'ai'].includes(
        file?.file?.ext || '',
      ) ||
        file?.file?.previews?.length ||
        context.type === 'documents') ? (
        <>
          <SectionCollapse
            label={context.type === 'documents' ? 'Full-text search' : 'OCR'}
            id="ocr"
          >
            <Detail
              left="Status"
              actions={[
                {
                  label: 'Process',
                  icon: 'rotate',
                  onClick: async () => {
                    getStore('FILES').addOrUpdate({
                      ...file,
                      processing: {
                        ...(file.processing || {}),
                        indexing: {
                          isProcessing: true,
                        },
                      },
                    });
                    context.reParseFile(file?._id, {
                      forceOCRIndexing: true,
                    });
                  },
                },
              ]}
              right={
                file?.processing?.indexing?.isProcessing ? (
                  <Loader size="xs" color="dark" />
                ) : file?.processing?.indexing?.status?.code ===
                  'WITH_OCR_CONTENT' ? (
                  'Processed'
                ) : file?.processing?.indexing?.status?.code ===
                  'EMPTY_OCR_CONTENT' ? (
                  'Nothing found'
                ) : (
                  'Not processed'
                )
              }
            />
          </SectionCollapse>
        </>
      ) : (
        <></>
      )}
      {!disabledSections?.includes('license') &&
        userStatus?.activeServices?.license_management &&
        context.type !== 'documents' && (
          <SectionCollapse label="License" id="license">
            {Object.keys(file.license || {})
              ?.filter((p) => {
                const value = file.license?.[p as keyof typeof file.license];
                return Array.isArray(value) ? !!value.length : !!value;
              })
              ?.sort((pa, pb) => {
                const keya = pa as keyof typeof file.license;
                const keyb = pb as keyof typeof file.license;
                if (keya === 'startDate') {
                  return -1;
                }
                if (keya === 'expirationDate') {
                  return -1;
                }
                return 0;
              })
              .map((key) =>
                renderAttribute(
                  key,
                  file.license?.[key as keyof typeof file.license],
                  License.find((p) => p.key === key),
                ),
              )}
            <PixiButton
              variant="light"
              size="xs"
              onClick={() =>
                openFileSettingsPopup(file, { defaultTab: 'license' })
              }
            >
              Edit
            </PixiButton>
          </SectionCollapse>
        )}
    </>
  );
}
