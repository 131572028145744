import {
  Alert,
  Anchor,
  Avatar,
  Badge,
  Box,
  Card,
  Collapse,
  ColorSwatch,
  Divider,
  Group,
  GroupProps,
  Image,
  Input,
  JsonInput,
  Loader,
  NavLink,
  Progress,
  Stack,
  TextInput,
  Textarea,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import FeedbackForm from '@pixi/components/AssetQuickForms/FeedbackForm';
import VariantForm from '@pixi/components/AssetQuickForms/VariantForm';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { formatDuration } from '@pixi/helpers/utils';
import { DjangoIdToName, useDjangoIdToEmail } from 'components/DjangoIdToName';
import { format, formatDistanceToNow } from 'date-fns';
import { useUserContext } from 'hooks';
import { useEffect, useState } from 'react';
import {
  bytesToSize,
  copyTextToClipboard,
  openInNewTab,
  truncate,
} from 'utils';
import { useAssetEditPanel } from '../context';
import { Detail, SectionCollapse } from '../elements';
import { useAssetGridContext } from '@pixi/components/AssetGrid/AssetGridContext';
import PixiForm from '@pixi/elements/Form';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import {
  createAppToast,
  openCollectionPreview,
  openFileSettingsPopup,
} from '@pixi/AppController';
import PixiCollapse from '@pixi/elements/Collapse';
import PixiTooltip from '@pixi/elements/Tooltip';
import AssetEditPanelUsage from './Sections/Usage';
import AssetEditPanelApprovals from './Sections/Approvals';
import AssetEditPanelFeedback from './Sections/Feedback';
import AssetEditPanelMetadata from './Sections/Metadata';
import AssetEditPanelVariants from './Sections/Variants';
import AssetEditPanelSlides from './Sections/Slides';
import { fileSourceToText } from '../../../../../../@common/functions/fileSourceToText';
import AssetEditPanelAssetDetails from './Sections/AssetDetails';
import AssetEditPanelAssetImageDetails from './Sections/ImageDetails';
import AssetEditPanelAssetVideoDetails from './Sections/VideoDetails';
import AssetEditPanelThumbnail from './Sections/Thumbnail';
import AssetEditPanelVersion from './Sections/Version';

export default function AssetEditPanelOverview() {
  const { file, disabledSections, focus } = useAssetEditPanel();

  return (
    <>
      {focus === 'feedback' && <AssetEditPanelFeedback />}
      {(focus === 'approvals' ||
        (file?.approval?.status &&
          file?.approval?.status !== 'approved' &&
          file?.approval?.status !== 'rejected')) && (
        <AssetEditPanelApprovals />
      )}
      <AssetEditPanelAssetDetails />
      <AssetEditPanelAssetImageDetails />
      <AssetEditPanelAssetVideoDetails />
      <AssetEditPanelThumbnail />
      <AssetEditPanelVariants />
      <AssetEditPanelVersion />
      <AssetEditPanelSlides />
      <AssetEditPanelMetadata />
      {focus !== 'feedback' && !disabledSections?.includes('feedback') && (
        <AssetEditPanelFeedback />
      )}
      <AssetEditPanelUsage />
    </>
  );
}
