import {
  Alert,
  Box,
  ColorSwatch,
  Group,
  Progress,
  Stack,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import PixiIcon from '@pixi/elements/Icon';
import { format, formatDistanceToNow, formatDuration } from 'date-fns';
import PixiButton from '@pixi/elements/Button';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import { openFileSettingsPopup } from '@pixi/AppController';
import { DjangoIdToName } from 'components/DjangoIdToName';

export default function AssetEditPanelVersion() {
  const { file, disabledSections, fileToolbar } = useAssetEditPanel();
  const uploadNewVersionAction = fileToolbar.toolbarFlattened?.find(
    (a) => a.id === 'upload_new_version',
  );
  return (
    <>
      {!disabledSections?.includes('version') && (
        <SectionCollapse label="Version" id="version">
          <Stack>
            <Detail left="Version" right={file?.file?.version || 1} />
            {file?.file?.uploaded_at && (
              <Detail
                left="Updated"
                tooltip={format(file?.file?.uploaded_at, 'yyyy-MM-dd HH:mm')}
                right={`${formatDistanceToNow(file?.file?.uploaded_at)} ago`}
              />
            )}
            {file?.file?.uploaded_by && (
              <Detail
                left="Updated by"
                right={<DjangoIdToName value={file?.file?.uploaded_by} />}
              />
            )}
          </Stack>
          {uploadNewVersionAction ? (
            uploadNewVersionAction.customRender?.(
              <PixiButton variant="light" size="xs">
                {uploadNewVersionAction.label}
              </PixiButton>,
              {
                setIsFreezeDropdown: () => {},
                isOpen: true,
                onClose: () => {},
              },
            )
          ) : (
            <></>
          )}
        </SectionCollapse>
      )}
    </>
  );
}
