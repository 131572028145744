import { ColorSwatch, Group, Stack, TextInput, ThemeIcon } from '@mantine/core';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import PixiIcon from '@pixi/elements/Icon';

export default function AssetEditPanelAssetImageDetails() {
  const { file } = useAssetEditPanel();
  return (
    file?.file?.image_details &&
    file?.file?.ext !== 'pdf' &&
    !file?.file?.contentType?.includes('video') && (
      <SectionCollapse label="Image details" id="image_details">
        <Stack>
          <Detail
            left="Resolution"
            right={`${file?.file?.image_details?.width}x${file?.file?.image_details?.height}`}
          />
          <Detail
            left="Orientation"
            rightProps={{ tt: 'capitalize' }}
            right={
              <>
                {file?.file?.image_details?.orientation}
                <ThemeIcon variant="transparent" size="sm" color="gray">
                  <PixiIcon
                    size="xs"
                    name={
                      file?.file?.image_details?.orientation === 'landscape'
                        ? 'arrows-left-right'
                        : 'arrows-up-down'
                    }
                  />
                </ThemeIcon>
              </>
            }
          />
          <Detail
            left="Thumbnails"
            right={<>{file?.file?.previews?.map((p) => p.name).join(', ')}</>}
          />
          <Detail
            left="Palette"
            right={
              <Group align="center" gap="5" key={file._id}>
                {Object.values(file?.file?.image_details?.palette || {}).map(
                  (hex) => {
                    return <ColorSwatch key={hex} color={hex} size={12} />;
                  },
                )}
              </Group>
            }
          />
          {/* {file?.file?.exiftool?.DateCreated && (
          <Detail
            left="Picture taken"
            right={format(
              new Date(file?.file?.exiftool?.DateCreated),
              'yyyy-MM-dd HH:mm',
            )}
          />
        )} */}
        </Stack>
      </SectionCollapse>
    )
  );
}
