import { Stack, TextInput } from '@mantine/core';
import { DjangoIdToName } from 'components/DjangoIdToName';
import { format, formatDistanceToNow } from 'date-fns';
import { bytesToSize, copyTextToClipboard, truncate } from 'utils';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import PixiForm from '@pixi/elements/Form';
import { createAppToast } from '@pixi/AppController';
import { fileSourceToText } from '../../../../../../../@common/functions/fileSourceToText';

export default function AssetEditPanelAssetDetails() {
  const { file, context, disabledSections, focus } = useAssetEditPanel();
  return (
    <>
      <SectionCollapse
        label="File details"
        id="file_details"
        defaultOpen={focus !== 'feedback' && focus !== 'approvals'}
      >
        <Stack>
          <Detail
            left="Name"
            right={truncate(file?.name, 30, '...')}
            tooltip={file?.name}
            actions={[
              {
                label: 'edit',
                icon: 'pen',
                wrapper: (target) => (
                  <PixiForm<{ name: string }>
                    type="dropdown"
                    position="bottom-end"
                    title="Change name"
                    onSubmit={async (data) => {
                      await context.savePartial({
                        _id: file._id,
                        name: data.name,
                      });
                    }}
                    form={{
                      name: {
                        key: 'name',
                        value: file.name || '',
                        render: ({ value, setValue }) => (
                          <TextInput
                            value={value}
                            onChange={(event) => {
                              setValue(event.currentTarget.value);
                            }}
                            autoFocus
                            onFocus={(event) => event.currentTarget?.select()}
                          />
                        ),
                      },
                    }}
                    target={target}
                  />
                ),
              },
              {
                label: 'Copy to clipboard',
                icon: 'copy',
                tooltip: 'Copy to clipboard',
                onClick: () => {
                  copyTextToClipboard(file?.name);
                  createAppToast({
                    message: 'File name copied to clipboard',
                    id: file?._id as string,
                  });
                },
              },
            ]}
          />
          {!disabledSections?.includes('file') && (
            <>
              <Detail left="File Type" right={file?.file?.ext?.toUpperCase()} />
              <Detail
                left="Size"
                right={bytesToSize(file?.file?.contentLength)}
              />
            </>
          )}
          <Stack>
            {!disabledSections?.includes('file') && (
              <Detail
                left="Source"
                right={fileSourceToText(file?.import?.from || '')}
              />
            )}
            <Detail
              left={
                disabledSections?.includes('uploaded') ? 'Created' : 'Uploaded'
              }
              right={`${formatDistanceToNow(new Date(file?.createdAt))} ago`}
              tooltip={format(new Date(file?.createdAt), 'yyyy-MM-dd HH:mm')}
            />
            <Detail
              left={
                disabledSections?.includes('uploaded')
                  ? 'Created by'
                  : 'Uploaded by'
              }
              right={
                <>
                  <DjangoIdToName
                    value={file?.createdBy || file?.file?.uploaded_by}
                  />
                  {/* <Avatar
                    size={20}
                    radius="xs"
                    src={DjangoIdToImage({ value: file?.createdBy })}
                  /> */}
                </>
              }
              tooltip={
                <DjangoIdToName
                  value={file?.createdBy || file?.file?.uploaded_by}
                />
              }
            />
          </Stack>
        </Stack>
      </SectionCollapse>
    </>
  );
}
