import { Group, Image, Stack, ThemeIcon } from '@mantine/core';
import VariantForm from '@pixi/components/AssetQuickForms/VariantForm';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';

export default function AssetEditPanelVariants() {
  const { file, fileToolbar, context, disabledSections } = useAssetEditPanel();

  return (
    <>
      {file?.type !== 'documents' &&
        !disabledSections?.includes('variants') && (
          <SectionCollapse label="Variants" id="variants">
            <Stack>
              {file?.file?.variants?.map((variant) => (
                <PixiDropdown
                  trigger="hover"
                  position="left"
                  key={variant.name}
                  target={
                    <Group gap="xs">
                      <Detail
                        left={
                          !variant.alias ? (
                            <VariantForm
                              file={file}
                              variant={variant}
                              target={
                                <PixiButton
                                  leftSection={
                                    <PixiIcon
                                      name="pen"
                                      size="sm"
                                      variant="filled"
                                    />
                                  }
                                  variant="light"
                                  size="xs"
                                >
                                  {variant.name}
                                </PixiButton>
                              }
                            />
                          ) : (
                            <Group wrap="nowrap">
                              <ThemeIcon
                                size="sm"
                                radius="xs"
                                variant="default"
                                style={{ overflow: 'hidden' }}
                              >
                                <Image
                                  flex={0}
                                  w="100%"
                                  h="100%"
                                  src={variant.url}
                                />
                              </ThemeIcon>
                              <PixiText
                                w="100%"
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {variant.alias || variant.name}
                              </PixiText>
                            </Group>
                          )
                        }
                        right={
                          <Group wrap="nowrap" gap="5" justify="flex-end">
                            <VariantForm
                              file={file}
                              variant={variant}
                              target={
                                <PixiButton
                                  variant="light"
                                  px="xs"
                                  color="gray"
                                  size="xs"
                                >
                                  <PixiIcon name="pen" size="sm" />
                                </PixiButton>
                              }
                            />
                            <PixiConfirm
                              title="Are you sure?"
                              description="The variant will be deleted. This can't be reverted."
                              position="bottom-end"
                              confirmLabel="Delete"
                              confirmProps={{
                                color: 'red',
                              }}
                              onConfirm={async () => {
                                await context.savePartial({
                                  _id: file._id,
                                  $pull: {
                                    'file.variants': {
                                      id: variant.id,
                                    },
                                  },
                                });
                              }}
                            >
                              <PixiButton
                                variant="light"
                                px="xs"
                                color="gray"
                                size="xs"
                              >
                                <PixiIcon name="trash-can" size="sm" />
                              </PixiButton>
                            </PixiConfirm>
                          </Group>
                        }
                      />
                    </Group>
                  }
                >
                  <Image w="100%" maw={250} src={variant.url} />
                </PixiDropdown>
              ))}
              <PixiButton
                w="100%"
                variant="light"
                size="xs"
                onClick={async () => {
                  await fileToolbar.api.createVariant();
                }}
              >
                Create variant
              </PixiButton>
            </Stack>
          </SectionCollapse>
        )}
    </>
  );
}
