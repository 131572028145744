import {
  Alert,
  ColorSwatch,
  Group,
  Progress,
  Stack,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import PixiIcon from '@pixi/elements/Icon';
import { format, formatDuration } from 'date-fns';
import PixiButton from '@pixi/elements/Button';

export default function AssetEditPanelAssetVideoDetails() {
  const { file, scanMetadata, scanMetadataProgress } = useAssetEditPanel();
  const videoInfo = file?.file?.media_details?.video?.streams?.find(
    (s) => s.codec_type === 'video',
  );
  return (
    file?.file?.contentType?.includes('video') && (
      <>
        <SectionCollapse label="Video details" id="video_details">
          {videoInfo ? (
            <Stack>
              <Detail
                left="Codec"
                tooltip={videoInfo.codec_long_name}
                rightProps={{
                  ff: 'mono',
                }}
                right={videoInfo?.codec_name?.toUpperCase()}
              />
              {typeof videoInfo.duration === 'number' && (
                <Detail
                  left="Duration"
                  rightProps={{
                    ff: 'mono',
                  }}
                  right={<>{formatDuration(videoInfo.duration)}</>}
                />
              )}
              <Detail
                left="Resolution"
                right={`${videoInfo.width}x${videoInfo.height}`}
                rightProps={{
                  ff: 'mono',
                }}
              />
              {file?.file?.media_details?.video?.format?.tags
                ?.creation_time && (
                <Detail
                  left="Recorded"
                  right={format(
                    new Date(
                      file?.file?.media_details?.video?.format?.tags?.creation_time,
                    ),
                    'yyyy-MM-dd HH:mm',
                  )}
                />
              )}
            </Stack>
          ) : (
            <>
              <Alert color="primary">
                <Stack gap={5}>
                  <PixiButton
                    fullWidth
                    size="xs"
                    leftSection={<PixiIcon name="rotate" variant="filled" />}
                    onClick={async () => {
                      await scanMetadata();
                    }}
                  >
                    Scan details
                  </PixiButton>
                  {!!scanMetadataProgress?.percentage && (
                    <Progress
                      value={scanMetadataProgress?.percentage}
                      radius="xs"
                      animated
                    />
                  )}
                </Stack>
              </Alert>
            </>
          )}
        </SectionCollapse>
      </>
    )
  );
}
