import PixiButton from '@pixi/elements/Button';
import { SectionCollapse } from '../../elements';
import { openFileSettingsPopup } from '@pixi/AppController';
import { useAssetEditPanel } from '../../context';

export default function AssetEditPanelSlides() {
  const { file } = useAssetEditPanel();
  if (!file?.file?.slides?.length) {
    return <></>;
  }
  return (
    <>
      <SectionCollapse label={<>Slides</>} id="slides">
        <PixiButton
          variant="light"
          size="xs"
          onClick={() => {
            openFileSettingsPopup(file, {
              defaultTab: 'slides',
            });
          }}
        >
          Edit
        </PixiButton>
      </SectionCollapse>
    </>
  );
}
