import { useAssetGridContext } from '@pixi/components/AssetGrid/AssetGridContext';
import { useEffect, useState } from 'react';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiButton from '@pixi/elements/Button';
import { Loader } from '@mantine/core';

export default function AssetEditPanelUsage() {
  const { context } = useAssetGridContext();
  const { file, disabledSections } = useAssetEditPanel();
  const [usage, setUsage] = useState<{
    data?: {
      totalDownloads?: number;
      totalPreviews?: number;
    };
  }>({});
  const [isLoadingUsage, setIsLoadingUsage] = useState(false);
  type usagePeriods = '7d' | '14d' | '30d' | '180d';
  const [usagePeriod, setUsagePeriod] = useState<usagePeriods>('7d');
  const usagePeriodData: Array<{ value: usagePeriods; text: string }> = [
    {
      value: '7d',
      text: 'Last 7 days',
    },
    {
      value: '14d',
      text: 'Last 14 days',
    },
    {
      value: '30d',
      text: 'Last month',
    },
    {
      value: '180d',
      text: 'Last 3 months',
    },
  ];
  const getUsage = async () => {
    if (disabledSections?.includes('usage')) {
      return;
    }
    setIsLoadingUsage(true);
    const usage = await context.request.getUsage(file._id, usagePeriod);
    setIsLoadingUsage(false);
    setUsage(usage);
  };

  useEffect(() => {
    getUsage();
  }, [usagePeriod]);

  if (disabledSections?.includes('usage')) {
    return <></>;
  }

  return (
    <>
      <SectionCollapse
        label={<>Usage</>}
        onToggle={() => getUsage()}
        id="usage"
      >
        {isLoadingUsage ? (
          <Loader />
        ) : (
          <>
            <Detail
              left="Downloads"
              right={usage?.data?.totalDownloads || '0'}
            />
            <Detail left="Previews" right={usage?.data?.totalPreviews || '0'} />
            <PixiDropdown
              closeOnItemClick
              target={
                <PixiButton size="xs" variant="light" color="primary">
                  {usagePeriodData.find((p) => p.value === usagePeriod)?.text}
                </PixiButton>
              }
            >
              <PixiDropdown.Label>Time interval</PixiDropdown.Label>
              {usagePeriodData.map((d) => (
                <PixiDropdown.Item
                  key={d.value}
                  value={d.value}
                  onClick={() => setUsagePeriod(d.value)}
                >
                  {d.text}
                </PixiDropdown.Item>
              ))}
            </PixiDropdown>
          </>
        )}
      </SectionCollapse>
    </>
  );
}
